function TooltipIt(element)
{
    // The element to have the tooltip.
    this.element = document.querySelector(element);
    // The title from the element.
    this.title   = this.element.getAttribute('data-title') || 'Set the title via data-title attribute';

    this.init = function()
    {
        var scope = this;

        // Build the structure when hovering the target element.
        this.element.addEventListener('mouseover', function() {
            scope.ttpBuildStructure();
        });

        // Remove the structure when mouse leaves the target element.
        this.element.addEventListener('mouseout', function() {
            scope.ttpRemoveStructure();
        });
    };

    this.ttpBuildStructure = function()
    {
        // Building the tooltip title.
        this.ttpTitle = document.createElement('span');
        this.ttpTitle.classList.add('ttp-title');
        this.ttpTitle.appendChild(document.createTextNode(this.title));

        // Building the tooltip container.
        this.ttpContainer = document.createElement('div');
        this.ttpContainer.classList.add('ttp-container');
        this.ttpContainer.style.position = 'fixed';

        // Appending the title to the container.
        this.ttpContainer.appendChild(this.ttpTitle);

        // Appending structure to the document right after the target element.
        this.ttpAppendStructure(this.element, this.ttpContainer);
    }

    this.ttpRemoveStructure = function()
    {
        // Removing the tooltip structure.
        this.ttpContainer.remove();
    };

    this.ttpAppendStructure = function(referenceNode, newNode)
    {
        // Appending tooltip structure right after the target element.
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);

        // Adding mouse move function so the tooltip follows the cursor.
        this.ttpOnMouseMove();
    };

    this.ttpOnMouseMove = function()
    {
        var scope = this;

        document.addEventListener('mousemove', function(e) {
            var mouseX = e.clientX;
            var mouseY = e.clientY;

            scope.ttpContainer.style.top = mouseY - 5 +'px';
            scope.ttpContainer.style.left = 20 + mouseX+'px';
        });
    }

    // Initializing.
    this.init();
}
